<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Helpdesk / Chamado</h1>
    <div v-if="(ticket)" class="form-group card-principal">
      <div class="row">
        <div class="col-auto">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="col">
        </div>
        <div class="col-auto">
          <div class="input-group mb-3">
            <a v-bind:href="printCarnetUrl" target="_blank" class="btn btn-secondary">
              <font-awesome-icon :icon="['fas', 'print']" fixed-width class="me-1"/>
            </a>
            <button v-if="!imOnCard()" @click="handleEnterCard()" class="btn btn-primary">
              <font-awesome-icon :icon="['fas', 'sign-in-alt']" fixed-width class="me-1"/>Entrar
            </button>
            <button v-if="imOnCard() && ticket.status === 'pending'" @click="handleLeftCard()" class="btn btn-warning">
              <font-awesome-icon :icon="['fas', 'sign-out-alt']" fixed-width class="me-1"/>Sair
            </button>
            <button v-if="(!podeEncerrarTicket() && ticket.status !== 'done')" class="btn btn-secondary" disabled>
              <font-awesome-icon :icon="['fas', 'check-double']" fixed-width class="me-1"/>Encerrar
            </button>
            <button v-if="podeEncerrarTicket()" v-on:click="encerrarTicketHandle()" :disabled="!imOnCard()" class="btn btn-success">
              <font-awesome-icon :icon="['fas', 'check-double']" fixed-width class="me-1"/>Encerrar
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4>
            <strong>CHAMADO</strong> #{{ticket.id}}
            <span v-if="!tickpending()" class="text-danger">(encerrado)</span>
          </h4>
        </div>
      </div>

      <div class="row g-0">

        <div class="col-12 col-md-6 col-lg-4 p-1">
          <!-- dados do ticket -->
          <div class="card p-2 mb-2">
            <p class="fw-bold mb-0">
              <font-awesome-icon :icon="['far', 'address-card']" fixed-width class="me-1"/>
              Dados do chamado
            </p>

            <!-- customer -->
            <div class="form-group">
              <label class="small">
                Cliente
              </label>
              <div class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'user']" class="small" />
                </span>
                <span v-if="(!currentCustomer)" class="input-group-text form-control">
                  <font-awesome-icon :icon="['fas', 'spinner']" spin class="small" />
                </span>
                <span v-if="(currentCustomer)" class="input-group-text form-control text-truncate">
                  {{ currentCustomer.companyName || currentCustomer.name }}
                </span>
                <button v-if="(ticket.status === 'pending' && ticket.customerId)" @click="handleRemoveCustomer()" class="btn btn-outline-danger" type="button">
                  <font-awesome-icon :icon="['fas', 'times']" class="small" />
                </button>
                <button v-if="(ticket.status === 'pending')" @click="handleSwitchCustomer()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'pen']" class="small" />
                </button>
              </div>
            </div>

            <!-- subscription -->
            <div class="form-group">
              <label class="small">
                Assinatura
              </label>
              <div v-if="(!form.subscriptionIdEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'user']" class="small" />
                </span>
                <span class="input-group-text form-control text-truncate">
                  {{ subscriptionData(ticket.subscriptionId) || '-' }}
                </span>
                <button v-if="(ticket.status === 'pending')" @click="handleSwitchSubscription()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'pen']" class="small" />
                </button>
              </div>

              <div v-if="(form.subscriptionIdEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'user']" class="small" />
                </span>
                <select v-model="form.subscriptionId" class="form-select">
                  <option :value="'00000000-0000-0000-0000-000000000000'">Nenhuma assinatura</option>
                  <option v-for="sub in subscriptions" :key="sub.id" :value="sub.id">{{sub.login}}</option>
                </select>
                <button @click="handleCancelSubscriptionChange()" class="btn btn-danger" type="button">
                  <font-awesome-icon :icon="['fas', 'times']" class="small" />
                </button>
                <button @click="handleSaveSubscriptionChange()" class="btn btn-success" type="button">
                  <font-awesome-icon :icon="['far', 'save']" class="small" />
                </button>
              </div>
            </div>

            <!-- due date -->
            <div class="form-group">
              <label class="small">
                Previsão
                <span v-if="isLate()" class="badge bg-danger ms-1 blinking">
                  Atrasado
                  <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="small ms-1" />
                </span>
              </label>
              <div v-if="(!novaPrevisao)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'calendar']" class="small" />
                </span>
                <span class="input-group-text form-control">
                  {{ shared().godate.toMysqlUTC(ticket.dueDate) || '-' }}
                </span>
                <button v-if="(ticket.status === 'pending')" @click="novaPrevisaoHandle()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'pen']" class="small" />
                </button>
              </div>

              <div v-if="(!!novaPrevisao)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'calendar']" class="small" />
                </span>
                <input type="date" v-model="novaPrevisao" class="form-control">
                <button v-on:click="novaPrevisao = undefined" class="btn btn-danger" type="button">
                  <font-awesome-icon :icon="['fas', 'times']" class="small" />
                </button>
                <button v-on:click="salvaNovaPrevisaoHandle()" class="btn btn-success" type="button">
                  <font-awesome-icon :icon="['far', 'save']" class="small" />
                </button>
              </div>
            </div>

            <!-- short description -->
            <div class="form-group">
              <label class="small">Descrição curta</label>
              <div v-if="(!form.shortDescriptionEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'comments']" class="small" />
                </span>
                <span class="input-group-text form-control text-truncate">
                  {{ ticket.shortDescription || '-' }}
                </span>
                <button v-if="(ticket.status === 'pending')" @click="handleChangeDescription()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'pen']" class="small" />
                </button>
              </div>

              <div v-if="(form.shortDescriptionEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'comments']" class="small" />
                </span>
                <input v-model="form.shortDescription" type="text" maxlength="150" class="form-control">
                <button @click="handleCancelDescriptionChange()" class="btn btn-danger" type="button">
                  <font-awesome-icon :icon="['fas', 'times']" class="small" />
                </button>
                <button @click="handleSaveDescriptionChange()" class="btn btn-success" type="button">
                  <font-awesome-icon :icon="['far', 'save']" class="small" />
                </button>
              </div>
            </div>

          </div>
        </div>
        <!-- dados do ticket -->

        <!-- informações de contato -->
        <div class="col-12 col-md-6 col-lg-4 p-1">
          <div class="card p-2 mb-2">
            <p class="fw-bold mb-0">
              <font-awesome-icon :icon="['far', 'address-card']" fixed-width class="me-1"/>
              Informações de contato
            </p>

            <!-- caller name -->
            <div class="form-group">
              <label class="small">Quem entrou em contato?</label>
              <div v-if="(!form.callerNameEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'comment']" class="small" />
                </span>
                <span class="input-group-text form-control text-truncate">
                  {{ ticket.callerName }}
                </span>
                <button v-if="(ticket.status === 'pending')" @click="handleChangeCallerName()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'pen']" class="small" />
                </button>
              </div>

              <div v-if="(form.callerNameEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['far', 'comment']" class="small" />
                </span>
                <input v-model="form.callerName" type="text" maxlength="150" class="form-control">
                <button @click="handleCancelCallerNameChange()" class="btn btn-danger" type="button">
                  <font-awesome-icon :icon="['fas', 'times']" class="small" />
                </button>
                <button @click="handleSaveCallerNameChange()" class="btn btn-success" type="button">
                  <font-awesome-icon :icon="['far', 'save']" class="small" />
                </button>
              </div>
            </div>

            <!-- caller's contact -->
            <div class="form-group">
              <label class="small">Contato desta pessoa</label>
              <div v-if="(!form.contactForCallbackEdit)" class="input-group input-group-sm">
                <span class="input-group-text">@</span>
                <span class="input-group-text form-control text-truncate">
                  {{ ticket.contactForCallback }}
                </span>
                <button v-if="(ticket.status === 'pending')" @click="handleChangeCallbackContact()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'pen']" class="small" />
                </button>
              </div>

              <div v-if="(form.contactForCallbackEdit)" class="input-group input-group-sm">
                <span class="input-group-text">@</span>
                <input v-model="form.contactForCallback" type="text" maxlength="150" class="form-control">
                <button @click="handleCancelCallbackContactChange()" class="btn btn-danger" type="button">
                  <font-awesome-icon :icon="['fas', 'times']" class="small" />
                </button>
                <button @click="handleSaveCallbackContactChange()" class="btn btn-success" type="button">
                  <font-awesome-icon :icon="['far', 'save']" class="small" />
                </button>
              </div>
            </div>

          </div>
        </div>
        <!-- informações de contato -->

        <div class="col-12 col-md-12 col-lg-4 p-1">
          <div class="card p-2 mb-2">
            <p class="fw-bold mb-0">
              <font-awesome-icon :icon="['fas', 'users']" fixed-width class="me-1"/>
              Envolvidos
            </p>

            <!-- caller name -->
            <div class="form-group">
              <label class="small">Grupo</label>
              <div v-if="(!form.groupIdEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['fas', 'users']" class="small" />
                </span>
                <span class="input-group-text form-control">
                  {{ (groupById(ticket.groupId) && groupById(ticket.groupId).description) || '-' }}
                </span>
                <button v-if="(ticket.status === 'pending')" @click="handleChangeGroup()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'exchange-alt']" class="small" />
                </button>
              </div>

              <div v-if="(form.groupIdEdit)" class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['fas', 'users']" class="small" />
                </span>
                <select v-model="form.groupId" class="form-control">
                  <option :value="undefined">Nenhum</option>
                  <option v-for="group in groups" :key="group.id" :value="group.id">{{group.description}}</option>
                </select>
                <button @click="handleCancelGroupChange()" class="btn btn-danger" type="button">
                  <font-awesome-icon :icon="['fas', 'times']" class="small"/>
                </button>
                <button @click="handleSaveGroupChange()" class="btn btn-success" type="button">
                  <font-awesome-icon :icon="['far', 'save']" class="small"/>
                </button>
              </div>
            </div>

            <!-- caller name -->
            <div class="form-group">
              <label class="small">Pessoas</label>
              <div class="input-group input-group-sm">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['fas', 'user']" class="small" />
                </span>
                <span class="input-group-text form-control gap-1 overflow-auto">
                  <span v-for="userId in ticket.usersId" :key="userId" class="badge rounded-pill bg-primary text-bg-primary">
                    {{ operatorName(userId) }}
                    <span v-if="ticket.status === 'pending'" @click="handleConfirmRemoveUser(userId)" class="ps-2 pe-1 cursor-pointer">x</span>
                  </span>
                </span>
                <button @click="handleOpenModalAddUser()" class="btn btn-secondary" type="button">
                  <font-awesome-icon :icon="['fas', 'plus']" class="small" />
                </button>
              </div>
            </div>

          </div>
        </div>


        <!-- <div v-if="atividadePossuiAnexo(0)" class="col-12 col-md-4">
          <div class="card d-grid gap-2 p-2">
            <p class="fw-bold mb-0">
              <font-awesome-icon :icon="['fas', 'paperclip']" fixed-width class="me-1"/>
              Anexos
            </p>
            <div v-for="anexo in anexosAtividade(0)" :key="anexo.id" class="row g-1">
              <div class="col d-grid gap-2">
                <button v-on:click="baixaAnexo(anexo)" class="btn btn-sm btn-block btn-outline-secondary">
                  <font-awesome-icon :icon="['fas', 'download']" fixed-width class="me-1"/>{{apenasNomeArquivo(anexo.caminho)}}
                </button>
              </div>
              <div class="col-auto">
                <button v-on:click="removeAnexoHandle(anexo)" class="btn btn-sm btn-outline-danger">
                  <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                </button>
              </div>
            </div>
          </div>
        </div> -->

        <div class="col-12 p-1">
          <div class="card border-info">
            <div class="card-header border-info text-info">
              <strong>
                <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width/>
                Descrição detalhada
              </strong>
            </div>
            <ul class="list-group list-group-flush">
              <li v-if="(descriptionActions().length === 0)" class="list-group-item text">
                <span class="small fst-italic text-muted-2">nenhum detalhamento inserido...</span>
              </li>
              <li v-for="act in descriptionActions()" :key="act.id" class="list-group-item ">
                {{act.description}}
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
              </li>
            </ul>
          </div>

          <button v-if="!form.descriptionEdit && ticket.status === 'pending'" type="button" @click="handleAddNewDescription()" class="btn btn-outline-primary btn-block rounded-circle float-end mt-2">
            <font-awesome-icon :icon="['fas', 'plus']"/>
          </button>
          <div v-if="form.descriptionEdit" class="col-12">
            <p class="mb-0"><strong>Adicionar descrição detalhada</strong></p>
            <textarea v-model="form.description" maxlength="1024" class="form-control"></textarea>
            <div class="input-group mt-1">
              <button @click="handleCancelNewDescription()" class="btn btn-secondary" type="button">
                <font-awesome-icon :icon="['fas', 'times']" fixed-width/> Cancelar
              </button>
              <button @click="handleSaveNewDescription()" class="btn btn-success" type="button">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/> Adicionar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-if="(ticket)" class="form-group">
      <div v-if="(ticket.status === 'pending')" class="col-12 mt-2 p-2 bg-secondary rounded">
        <p class="mb-0 text-light"><strong>Adicionar mensagem privada</strong></p>
        <textarea v-model="form.message" maxlength="1024" class="form-control"></textarea>
        <div class="input-group mt-1">
          <button @click="handleCancelNewText()" :disabled="(!form.message)" class="btn btn-dark" type="button">
            <font-awesome-icon :icon="['fas', 'times']" fixed-width/> Cancelar
          </button>
          <button @click="handleSaveNewText()" class="btn btn-success" type="button">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width/> Adicionar
          </button>
        </div>
      </div>

      <div v-for="act in timelineActions()" :key="act.id" class="row no-gutters mb-0 p-3" style="width: calc(100vw - 3px);">
        <!-- created -->
        <blockquote v-if="act.action === 'created'" class="col-12 blockquote blockquote-custom px-4">
            <p class="mb-0 font-italic">Iniciou o chamado</p>
            <footer class="blockquote-footer pt-0 mt-0 border-top text-muted-2">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite> (mensagem privada)
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- created -->

        <!-- message -->
        <blockquote v-if="act.action === 'message'" class="col-12 blockquote blockquote-custom bg-secondary text-white p-4 shadow rounded">
            <div class="blockquote-custom-icon bg-info shadow-sm"><i class="fa fa-quote-left text-white"></i></div>
            <p class="mb-0 mt-2 font-italic">{{act.description}}</p>
            <footer class="blockquote-footer pt-2 mt-4 border-top text-muted-2">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite> (mensagem privada)
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- message -->

        <!-- add-someone -->
        <blockquote v-if="act.action === 'add-someone'" class="col-12 blockquote blockquote-custom px-4">
            <p class="mb-0 font-italic">Adicionou <b>{{operatorName(act.targetUserId)}}</b> ao cartão</p>
            <footer class="blockquote-footer pt-0 mt-0 border-top text-muted">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite>
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- add-someone -->

        <!-- remove-someone -->
        <blockquote v-if="act.action === 'remove-someone'" class="col-12 blockquote blockquote-custom px-4">
            <p class="mb-0 font-italic">Removeu <b>{{operatorName(act.targetUserId)}}</b> do cartão</p>
            <footer class="blockquote-footer pt-0 mt-0 border-top text-muted">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite>
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- remove-someone -->

        <!-- someone-enter -->
        <blockquote v-if="act.action === 'someone-enter'" class="col-12 blockquote blockquote-custom px-4">
            <p class="mb-0 font-italic"><b>{{operatorName(act.userId)}}</b> Entrou no cartão</p>
            <footer class="blockquote-footer pt-0 mt-0 border-top text-muted">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite>
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- someone-enter -->

        <!-- someone-left -->
        <blockquote v-if="act.action === 'someone-left'" class="col-12 blockquote blockquote-custom px-4">
            <p class="mb-0 font-italic"><b>{{operatorName(act.userId)}}</b> Saiu do cartão</p>
            <footer class="blockquote-footer pt-0 mt-0 border-top text-muted">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite>
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- someone-left -->

        <!-- due-date -->
        <blockquote v-if="act.action === 'due-date'" class="col-12 blockquote blockquote-custom px-4">
            <p class="mb-0 font-italic">Alterou data de previsão para <b>{{act.description}}</b></p>
            <footer class="blockquote-footer pt-0 mt-0 border-top text-muted">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite>
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- due-date -->

        <!-- change-group -->
        <blockquote v-if="act.action === 'change-group'" class="col-12 blockquote blockquote-custom px-4">
            <p class="mb-0 font-italic">Moveu o ticket para o grupo <b>{{(groupById(act.groupId) && groupById(act.groupId).description) || '-'}}</b></p>
            <footer class="blockquote-footer pt-0 mt-0 border-top text-muted">
                <cite title="Source Title">{{operatorName(act.userId)}}</cite>
                <p class="small mb-0 float-end text-muted-2 text-italic"><em>{{shared().godatetime.toMysql(act.createdAt, true)}}</em></p>
            </footer>
        </blockquote>
        <!-- change-group -->
      </div>
















      <div class="form-group m-0 p-0">
        <div v-if="(carregandoAtividades)" class="form-group">
            <font-awesome-icon :icon="['fas', 'spinner']" fixed-width class="me-1"/>
        </div>

        <p v-if="(carregandoAtividades)" class="mb-0 pt-2 fw-bold">Atividades:</p>


        <div v-if="(formAnotacao.visivel)" class="row mb-3">
          <div class="col-12 mb-3">
            <div class="form-group" v-bind:class="classeFormAnotacao()">
              <h5 class="w-100 fw-bold-">
                Adicionar anotação
                <button type="button" v-on:click="fechaNovaAnotacao()" class="btn btn-danger btn-sm float-end">
                  <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                </button>
              </h5>
              <textarea v-model="formAnotacao.mensagem" rows="4" class="form-control" maxlength="256"></textarea>
            </div>
          </div>
          <div v-if="(formAnotacao.anexos.length)" class="col-12 mb-2">
            <p class="fw-bold mb-0">Anexos:</p>
            <div v-for="anexo in formAnotacao.anexos" :key="anexo.id" class="form-group border pe-1 pt-1 pb-2">
              <label>File
                <input type="file" @change="handleFileUpload(anexo, $event)"/>
              </label>
              <button v-on:click="removeAnexoHandle(anexo)" class="btn btn-sm btn-outline-danger float-end" type="button">
                <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
              </button>
            </div>
          </div>
          <div class="col-12 col-md mb-2">
            <div class="form-group" v-bind:class="classeFormAnotacao()">
              <div class="dropdown d-grid gap-2">
                <button class="btn btn-block dropdown-toggle" type="button" id="triggerDDTipoMensagem" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{textoDDTipoAnotacao}}
                </button>
                <ul class="dropdown-menu dropdown-menu-dark w-100" aria-labelledby="triggerDDTipoMensagem">
                  <li><button class="dropdown-item text-warning" type="button" v-on:click="formAnotacao.tipo = 'atMensagemPrivada'">{{traduzTipoMensagem('atMensagemPrivada')}}</button></li>
                  <li><button class="dropdown-item text-success" type="button" v-on:click="formAnotacao.tipo = 'atMensagemPublica'">{{traduzTipoMensagem('atMensagemPublica')}}</button></li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-md-auto">
            <div class="d-grid gap-2">
              <button v-if="formAnotacao.tipo" v-on:click="novoAnexoHandle()" class="btn btn-block btn-outline-primary">
                <font-awesome-icon :icon="['fas', 'upload']" fixed-width class="me-1"/>
                Anexar arquivos
              </button>
            </div>
          </div> -->
          <div class="col-12 col-md-auto">
            <div class="d-grid gap-2">
              <button v-if="'atMensagemPrivada' === formAnotacao.tipo" v-on:click="adicionarAnotacaoHandle()" class="btn btn-block btn-warning">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>
                Adicionar
              </button>
              <button v-if="'atMensagemPublica' === formAnotacao.tipo" v-on:click="adicionarAnotacaoHandle()" class="btn btn-block btn-success">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>
                Adicionar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- modal-add-user -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-add-user" id="btn-modal-add-user-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-add-user" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Adicionar operador ao ticket
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-2">
                <label>Selecione o operador</label>
                <select v-model="modalAddUser.userId" class="form-control">
                  <option :value="undefined" disabled>Selecione</option>
                  <option v-for="user in users" :key="user.id" :value="user.id">{{user.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-add-user-fecha">Cancelar</button>
            <button type="button"
                @click="modalAddUserHandleAdd()"
                v-bind:disabled="!modalAddUserCanAddUserToTicket()"
                class="btn btn-success">
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-add-user -->
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import shared from '../modules/shared'
import store from '@/store'
import pesquisaCliente from '@/modules/pesquisa-cliente'
// import Avatar from 'vue-avatar'
// import Tooltip from "@/components/Tooltip.vue";
import confirmacao from '../modules/dialogo/confirmacao'
import baseUrl from '../modules/baseurl'
import godate from '../modules/godate'

export default {
  name: 'Ticket',
  components: {
    // Avatar,
    // Tooltip,
  },
  data() {
    return {
      meuID: 0,
      meuGrupoID: 0,
      ticketID: undefined,
      ticket: undefined,
      clienteSelecionado: undefined,
      carregandoAtividades: true,
      groups: [],
      users: [],
      actions: [],
      subscriptions: [],
      indiceAnexo: 0,
      novaPrevisao: undefined,
      currentCustomer: undefined,
      formAnotacao: {
        visivel: false,
        id: undefined,
        mensagem: '',
        tipo: '', // atMensagemPrivada, atMensagemPublica
        anexos: [],
      },
      form: {
        callerName: undefined,
        callerNameEdit: false,
        subscriptionId: false,
        subscriptionIdEdit: false,
        contactForCallback: undefined,
        contactForCallbackEdit: false,
        shortDescription: undefined,
        shortDescriptionEdit: false,
        groupId: undefined,
        groupIdEdit: false,
        description: undefined,
        descriptionEdit: false,
        message: undefined,
      },
      modalAddUser: {
        userId: undefined,
      },
    }
  },
  methods: {
    resetFormAnotacao() {
      this.formAnotacao.id = undefined;
      this.formAnotacao.mensagem = '';
      this.formAnotacao.tipo = 'atMensagemPrivada';
    },

    carregaMeuID() {
      const userData = store.getters['auth/getUserData'];
      this.meuID = userData.userId;
      this.meuGrupoID = userData.helpdeskGroupId;
    },

    carregaTicket() {
      const apiUrl = `/tickets/${this.ticketID}`;

      loading(true);
      this.clienteSelecionado = undefined;

      api.get(apiUrl).then(async res => {
        this.ticket = res.data;
        this.loadCustomer(this.ticket.customerId);
        loading(false);
      }).catch(error => {
        loading(false);

        dialogo.erroHttp(error).then(() => {
          this.$router.push({ path: `/helpdesk` });
        });
      });
    },

    carregaAtividades() {
      //const apiUrl = `/helpdesk/tickets/${this.ticketID}/actions?orderBy[created_at]=desc`;
      const apiUrl = `/tickets/${this.ticketID}/actions?paginate.cancel&orderBy[created_at]=asc`;

      this.carregandoAtividades = true;

      api.get(apiUrl).then(res => {
        this.carregandoAtividades = false;
        this.actions = res.data.items;
      }).catch(() => {
        this.carregandoAtividades = false;
      });
    },

    loadUsers() {
      api.get(`/users?paginate.cancel&orderBy[name]=asc`).then(res => {
        this.users = res.data.items;
        // this.users = [];

        // res.data.items.forEach(x => {
        //   if (x.id != this.meuID) {
        //     this.users.push(x);
        //   }
        // });
      }).catch(() => {
        setTimeout(() => {
          this.loadUsers();
        }, 1000);
      });
    },

    loadCustomer(id) {
      this.currentCustomer = undefined;
      if (!id) {
        this.currentCustomer = {name: "-"};
        return;
      }

      api.get(`/customers/${id}`).then(res => {
        this.currentCustomer = res.data;
      }).catch(() => {
        setTimeout(() => {
          this.loadCustomer(id)
        }, 5000);
      });

      this.listSubscriptions();
    },

    isLate() {
      if (this.ticket.status != 'pending') {
        return false;
      }

      const dueDate = shared.godate.toDate(this.ticket.dueDate);

      return (new Date() > dueDate);
    },




    operatorName(id) {
      const user = this.users.find(x => x.id === id);

      if (!user) {
        return '(invalid)';
      }

      return user.name;
    },

    timelineActions() {
      const actions = [
        'created',
        'message',
        'add-someone',
        'remove-someone',
        // 'status-change',
        // 'description',
        // 'message',
        'someone-enter',
        'someone-left',
        'change-group',
        // 'change-customer',
        // 'change-subscription',
        'due-date',
      ];

      return this.actions.filter(x => actions.includes(x.action));
    },

    groupById(id) {
      return this.groups.find(x => x.id === id);
    },

    descriptionActions() {
      const actions = ['description'];

      return this.actions.filter(x => actions.includes(x.action));
    },

    subscriptionData(subscriptionId) {
      const subscription = this.subscriptions.find(x => x.id === subscriptionId);
      if (!subscription) {
        return '';
      }

      const dateStr = godate.toMysql(subscription.createdAt);

      return `(${dateStr}) ${subscription.login}`;
    },

    handleConfirmAddUser(userId) {
      let msg = `Deseja adicionar o operador <b>${this.operatorName(userId)}</b>?`;
      if (userId === this.meuID) {
        msg = `Você quer se adicionar ao ticket?`;
      }

      confirmacao(msg).then(() => {
        const sendData = {
          action: "add-someone",
          targetUserId: userId
        }

        if (userId === this.meuID) {
          sendData.action = 'someone-enter';
          sendData.targetUserId = undefined;
        }

        loading(true);
        api.post(`/tickets/${this.ticketID}/actions`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.usersId.push(userId);
          document.getElementById('btn-modal-add-user-fecha').click();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleConfirmRemoveUser(userId) {
      let msg = `Deseja remover o operador <b>${this.operatorName(userId)}</b>?`;
      if (userId === this.meuID) {
        msg = `Você quer sair do ticket?`;
      }

      confirmacao(msg).then(() => {
        const sendData = {
          action: "remove-someone",
          targetUserId: userId
        }

        if (userId === this.meuID) {
          sendData.action = 'someone-left';
          sendData.targetUserId = undefined;
        }

        loading(true);
        api.post(`/tickets/${this.ticketID}/actions`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          for (let i = 0; i < this.ticket.usersId.length; i++) {
            if ( this.ticket.usersId[i] === userId) {
              this.ticket.usersId.splice(i, 1);
              break;
            }
          }
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    imOnCard() {
      return this.ticket.usersId && this.ticket.usersId.some(x => x === this.meuID);
    },

    handleEnterCard() {
      this.handleConfirmAddUser(this.meuID);
    },

    handleLeftCard() {
      this.handleConfirmRemoveUser(this.meuID);
    },

    handleOpenModalAddUser() {
      this.modalAddUser.userId = undefined;
      document.getElementById('btn-modal-add-user-abre').click();
    },

    handleRemoveCustomer() {
      confirmacao(`Confirma remover o cliente relacionado?`).then(() => {
          const sendData = {
            customerId: '00000000-0000-0000-0000-000000000000',
          }

          loading(true);
          api.patch(`/tickets/${this.ticketID}`, sendData).then(() => {
            loading(false);
            this.ticket.customerId = undefined;
            this.loadCustomer(undefined);
          }).catch(error => {
            loading(false);
            dialogo.erroHttp(error);
          });
        });
    },

    handleSwitchCustomer() {
      pesquisaCliente().then(customer => {
        confirmacao(`Confirma alterar o cliente relacionado?`).then(() => {
          const sendData = {
            customerId: customer.id
          }

          loading(true);
          api.patch(`/tickets/${this.ticketID}`, sendData).then(() => {
            loading(false);
            this.ticket.customerId = customer.id;
            this.currentCustomer = clone(customer);
            this.listSubscriptions();
          }).catch(error => {
            loading(false);
            dialogo.erroHttp(error);
          });
        });
      });
    },

    listSubscriptions() {
      this.subscriptions = [];

      api.get('/isp/subscriptions?customer_id='+ this.ticket.customerId).then(res => {
        this.subscriptions = res.data.items;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    handleSwitchSubscription() {
      this.form.subscriptionId = this.ticket.subscriptionId || '00000000-0000-0000-0000-000000000000';
      this.form.subscriptionIdEdit = !this.form.subscriptionIdEdit;
    },
    handleCancelSubscriptionChange() {
      this.form.subscriptionIdEdit = false;
    },
    handleSaveSubscriptionChange() {
      confirmacao(`Confirma fazer alteração da assinatura?`).then(() => {
        const sendData = {
          subscriptionId: this.form.subscriptionId
        }

        loading(true);
        api.patch(`/tickets/${this.ticketID}`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.subscriptionId = this.form.subscriptionId;
          if (this.ticket.subscriptionId == '00000000-0000-0000-0000-000000000000') {
            this.ticket.subscriptionId = undefined;
          }
          this.handleCancelSubscriptionChange();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    //handleChangeCallbackContact()
    handleChangeCallerName() {
      this.form.callerName = this.ticket.callerName;
      this.form.callerNameEdit = !this.form.callerNameEdit;
    },
    handleCancelCallerNameChange() {
      this.form.callerNameEdit = false;
    },
    handleSaveCallerNameChange() {
      confirmacao(`Confirma fazer alteração do nome do contato?`).then(() => {
        const sendData = {
          callerName: this.form.callerName
        }

        loading(true);
        api.patch(`/tickets/${this.ticketID}`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.callerName = this.form.callerName;
          this.handleCancelCallerNameChange();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleChangeCallbackContact() {
      this.form.contactForCallback = this.ticket.contactForCallback;
      this.form.contactForCallbackEdit = !this.form.contactForCallbackEdit;
    },
    handleCancelCallbackContactChange() {
      this.form.contactForCallbackEdit = false;
    },
    handleSaveCallbackContactChange() {
      confirmacao(`Confirma fazer alteração do contato?`).then(() => {
        const sendData = {
          contactForCallback: this.form.contactForCallback
        }

        loading(true);
        api.patch(`/tickets/${this.ticketID}`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.contactForCallback = this.form.contactForCallback;
          this.handleCancelCallbackContactChange();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleAddNewDescription() {
      this.form.description = this.ticket.description;
      this.form.descriptionEdit = !this.form.descriptionEdit;
    },
    handleCancelNewDescription() {
      this.form.descriptionEdit = false;
    },
    handleSaveNewDescription() {
      confirmacao(`Confirma adicionar nova descrição?`).then(() => {
        const _vm = this;
        const sendData = {
          action: "description",
          description: this.form.description
        }

        loading(true);
        api.post(`/tickets/${this.ticketID}/actions`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.description = _vm.description;
          _vm.description = '';
          this.handleCancelNewDescription();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleCancelNewText() {
      this.form.message = '';
    },
    handleSaveNewText() {
      confirmacao(`Confirma adicionar nova mensagem?`).then(() => {
        const sendData = {
          action: "message",
          description: this.form.message
        }

        loading(true);
        api.post(`/tickets/${this.ticketID}/actions`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.handleCancelNewText();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleChangeGroup() {
      this.form.groupId = this.ticket.groupId || undefined; // set null as undefined
      this.form.groupIdEdit = !this.form.groupIdEdit;
    },
    handleCancelGroupChange() {
      this.form.groupIdEdit = false;
    },
    handleSaveGroupChange() {
      confirmacao(`Confirma fazer alteração do grupo?`).then(() => {
        const sendData = {
          action: "change-group",
          groupId: this.form.groupId
        }

        loading(true);
        api.post(`/tickets/${this.ticketID}/actions`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.groupId = this.form.groupId;
          this.handleCancelGroupChange();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleChangeDescription() {
      this.form.shortDescription = this.ticket.shortDescription;
      this.form.shortDescriptionEdit = !this.form.shortDescriptionEdit;
    },
    handleCancelDescriptionChange() {
      this.form.shortDescriptionEdit = false;
    },
    handleSaveDescriptionChange() {
      confirmacao(`Confirma alterar a descrição?`).then(() => {
        const sendData = {
          shortDescription: this.form.shortDescription
        }

        loading(true);
        api.patch(`/tickets/${this.ticketID}`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.shortDescription = this.form.shortDescription;
          this.handleCancelDescriptionChange();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },







    ////////////////////////////////////////////////
    // MODAL ADD USER
    modalAddUserCanAddUserToTicket() {
      if (this.modalAddUser.userId) {
        return true;
      }

      return false;
    },

    modalAddUserHandleAdd() {
      this.handleConfirmAddUser(this.modalAddUser.userId);
    },
    // MODAL ADD USER
    ////////////////////////////////////////////////





    removeClienteSelecionado() {
      this.clienteSelecionado = undefined;
      this.ticket.cliente_id = 0;
    },

    abreModalPesquisaClientes() {
      pesquisaCliente().then(res => {
        this.clienteSelecionado = clone(res);
        this.ticket.cliente_id = res.id;
      });
    },

    carregaGrupos() {
      api.get(`/ticket-groups?paginate.cancel&orderBy["order"]=asc`).then(res => {
        this.groups = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaGrupos();
        }, 1000);
      });
    },

    nomeGrupo(id) {
      if (!id) {
        return 'Nenhum';
      }

      const grupo = this.groups.find(x => x.id === id);

      if (!grupo) {
        return 'Não encontrado';
      }

      return grupo.descricao;
    },

    salvaAlteracoes() {
      const sendData = clone(this.ticket);

      loading(true);

      return api.put(`/helpdesk/tickets/${sendData.id}`, sendData).then(() => {
        loading(false);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    // TODO: tornar esta função como global
    /**
     * @param {Date} data
     * @returns {string} Data no formato YYYY-MM-DD
     **/
    dateTimeToMySQLDateTimeTzBrasilStr(data) {
      const dia = shared.numbers.strZero(data.getDate(), 2);
      const mes = shared.numbers.strZero(data.getMonth()+1, 2);
      const hora = shared.numbers.strZero(data.getHours(), 2);
      const minuto = shared.numbers.strZero(data.getMinutes(), 2);
      const segundo = shared.numbers.strZero(data.getSeconds(), 2);

      return `${data.getFullYear()}-${mes}-${dia}T${hora}:${minuto}:${segundo}.000-03:00`;
    },

    novaPrevisaoHandle() {
      this.novaPrevisao = shared.godate.toMysqlUTC(this.ticket.dueDate);
    },

    salvaNovaPrevisaoHandle() {
      // const _vm = this;
      const sendData = {
          action: "due-date",
          description: this.novaPrevisao
      }

      loading(true);
      api.post(`/tickets/${this.ticketID}/actions`, sendData).then(res => {
        loading(false);
        this.actions.push(res.data);
        this.ticket.dueDate = shared.godate.fromMysql(this.novaPrevisao);
        this.novaPrevisao = undefined;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    novoAnexoHandle() {
      this.indiceAnexo++;

      const objAnexo = {
        id: this.indiceAnexo,
        file: undefined,
      }

      this.formAnotacao.anexos.push(objAnexo);
    },

    handleFileUpload(anexo, event){
      anexo.file = event.target.files[0];
    },

    salvarHandle() {
      this.salvaAlteracoes();
    },

    salvarESairHandle() {
      this.salvaAlteracoes().then(() => {
        this.$router.push({ path: `/helpdesk` });
      });
    },

    podeEncerrarTicket() {
      return (this.ticket.status == 'pending');
    },

    encerrarTicketHandle() {
      dialogo.confirmacao(`Confirma encerrar este ticket?`).then(() => {
        const sendData = {
          action: "status-change",
          description: "done"
        };

        loading(true);
        api.post(`/tickets/${this.ticketID}/actions`, sendData).then(res => {
          loading(false);
          this.actions.push(res.data);
          this.ticket.status = sendData.description;

          dialogo.alerta('O ticket foi encerrado com sucesso.').then(() => {
            this.$router.go(-1)
          });
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    adicionarAnotacaoHandle() {
      let formData = new FormData();
      formData.set('tipo', this.formAnotacao.tipo);
      formData.set('descricao', this.formAnotacao.mensagem);
      formData.set('total_anexos', this.formAnotacao.anexos.length);
      for (let index = 0; index < this.formAnotacao.anexos.length; index++) {
        const anexo = this.formAnotacao.anexos[index];
        formData.append(`anexo_${index + 1}`, anexo.file);
      }

      loading(true);
      return api.post(`/helpdesk/tickets/${this.ticketID}/actions`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(() => {
        loading(false);
        this.carregaTicket();
        this.resetFormAnotacao();
        this.fechaNovaAnotacao();
        this.carregaAtividades();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    atividadeValida(atividade) {
      //const actions = ['atMensagemPrivada', 'atMensagemPublica'];
      //return actions.includes(atividade.tipo);


      const actions = [
        "created",
        "status-change",
        "description",
        "message",
        "add-someone",
        "remove-someone",
        "someone-enter",
        "someone-left",
        "change-group",
        "change-customer",
        "change-subscription",
        "due-date",
      ];
      return actions.includes(atividade.action);
    },

    atividadePossuiAnexo(id) {
      return this.anexosAtividade(id).length > 0;
    },

    // TODO: REFATORAR
    baixaAnexo(anexo) {
      const arquivo = this.apenasNomeArquivo(anexo.caminho);

      api.get(`/helpdesk/tickets/${this.ticketID}/anexos/${anexo.id}/${arquivo}`, {responseType: 'arraybuffer'}).then(res => {
        loading(false);

        let blob = new Blob([res.data], { type: 'application/octet-stream' }); // octet-stream
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.apenasNomeArquivo(anexo.caminho);
        link.click();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    // TODO: REFATORAR
    removeAnexoHandle(anexo) {
      dialogo.confirmacao(`Confirma remover o anexo: "${this.apenasNomeArquivo(anexo.caminho)}"?`).then(() => {
        api.delete(`/helpdesk/tickets/${this.ticketID}/anexos/${anexo.id}`).then(() => {
          loading(false);
          const index = this.ticket.anexos_ticket.indexOf(anexo);
            if (index > -1) {
              this.ticket.anexos_ticket.splice(index, 1);
            }
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    // TODO: REFATORAR
    anexosAtividade(id) {
      if (!this.ticket || !this.ticket.anexos_ticket) {
        return [];
      }

      const anexos = [];

      for (let index = 0; index < this.ticket.anexos_ticket.length; index++) {
        const anexo = this.ticket.anexos_ticket[index];
        if (anexo.mensagem_id === id) {
          anexos.push(anexo);
        }
      }

      return anexos;
    },

    // TODO: REFATORAR
    apenasNomeArquivo(caminho) {
      if (!caminho) {
        return '';
      }
      const pedacos = caminho.split('/');
      return pedacos[pedacos.length-1];
    },

    tickpending() {
      return (this.ticket && this.ticket.status === 'pending' || this.ticket.status === 'waiting-for');
    },

    classeAtividade(atividade) {
      if (atividade.action === 'atEstagio' && atividade.descricao === 'etEncerrado') {
        return ['alert-danger'];
      }

      if (atividade.action === 'atAtendimentoIniciado') {
        return ['alert-secondary'];
      }

      return ['alert-primary'];
    },

// "created",
//         "status-change",
//         "description",
//         "message",
//         "add-someone",
//         "remove-someone",
//         "someone-enter",
//         "someone-left",
//         "change-group",
//         "change-customer",
//         "change-subscription",
//         "due-date",
    escreveAtividade(atividade) {
      switch (atividade.action) {
        case 'created':
          return 'Ticket criado.';

        case 'atSolicitante':
          return `Alteração do solicitante.\n • Anterior: ${atividade.descricao}`;

        case 'atContato':
          return `Alteração do contato.\n • Anterior: ${atividade.descricao}`;

        case 'change-customer':
          return `Alteração do cliente.\n • Anterior: ${atividade.descricao}`;

        case 'change-subscription':
          return `Alteração do assinatura.\n • Anterior: ${atividade.descricao}`;

        case 'atTitulo':
          return `Alteração da descrição curta.\n • Anterior: ${atividade.descricao}`;

        case 'atDescricao':
          return `Alteração da descrição longa.\n • Anterior: ${atividade.descricao}`;

        case 'change-group':
          return `Alteração do grupo.\n • Anterior: ${atividade.descricao}`;

        case 'atOperador':
          return `Alteração do operador.\n • Anterior: ${atividade.descricao}`;

        case 'status-change':
          switch (atividade.description) {
            case 'done':
              return `Ticket encerrado`;
          }

          return `Alteração do status.\n • Inválido`;

        case 'text':
          return `Enviou uma mensagem privada`;

        case 'atMensagemPublica':
          return `Enviou uma mensagem pública`;

        case 'atAtendimentoIniciado':
          return `Iniciou o atendimento`;
      }

      return 'Atividade inválida';
    },

    traduzTipoMensagem(tipo) {
      switch (tipo) {
        case 'atMensagemPrivada':
          return 'Interna (apenas funcionários)';

        case 'atMensagemPublica':
          return 'Público (pode ser visto pelo cliente)';

        default:
          return 'desconhecido';
      }
    },

    classeFormAnotacao() {
      switch (this.formAnotacao.tipo) {
        case 'atMensagemPrivada':
          return ['form-anotacao-privado'];

        case 'atMensagemPublica':
          return ['form-anotacao-publico'];
      }

      return [];
    },

    shared() {
      return shared
    },
  },
  created() {
    const ticketID = +this.$route.params.ticketID;

    if (!ticketID) {
      this.$router.push({ path: `/helpdesk` });
      return;
    }

    this.ticketID = ticketID;

    this.resetFormAnotacao();
    this.carregaMeuID();
    this.carregaTicket();
    this.carregaGrupos();
    this.loadUsers();
    this.carregaAtividades();
  },
  computed: {
    textoClienteSelecionado() {
      if (!this.clienteSelecionado) {
        return '';
      }

      return this.clienteSelecionado.razao_social;
    },

    printCarnetUrl() {
      return `${baseUrl}/tickets/print?id=${this.ticketID}`;
    },
  },
}
</script>


<style>
.form-anotacao-privado #triggerDDTipoMensagem,
.form-anotacao-privado > textarea {
  border-color: #ffc107;
  background-color: #ffc1072d;
  color: #463400;
}

.form-anotacao-publico #triggerDDTipoMensagem,
.form-anotacao-publico > textarea {
  border-color: #198754;
  background-color: #19875411;
  color: #198754;
}

.blockquote-custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -25px;
    left: 50px;
}

.blockquote-custom {
    position: relative;
    font-size: 1.1rem;
}

.blockquote-footer {
  font-size: 0.8rem;
}

.change-circle {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
}
</style>

<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Helpdesk / Chamado</h1>
    <div v-if="(ticket)" class="form-group card-principal">
      <div class="row">
        <div class="col-auto">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="col">
        </div>
        <div class="col-auto">
          <div class="input-group mb-3">
            <button v-on:click="salvarHandle()" class="btn btn-success">
              <font-awesome-icon :icon="['fas', 'check']" fixed-width class="me-1"/>Salvar
            </button>
            <button v-on:click="salvarESairHandle()" class="btn btn-outline-success">
              <font-awesome-icon :icon="['fas', 'arrow-up']" fixed-width class="me-1"/>Salvar e sair
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h4><strong>TICKET</strong> #{{ticket.id}}</h4>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-auto" style="width: 220px;">
          <div class="form-group">
            <label>Grupo</label>
            <select v-model="ticket.grupo_id" class="form-select select-icon">
              <option v-for="grupo in listaGrupos" :key="grupo.id" :value="grupo.id">• {{grupo.descricao}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Aos cuidados de</label>
            <select v-model="ticket.operador_id" class="form-select select-icon">
              <option :value="meuID">• Eu ({{nomeGrupo(meuGrupoID)}})</option>
              <option v-for="operador in listaOperadores" :key="operador.id" :value="operador.id">• {{operador.nome}} ({{nomeGrupo(operador.helpdeskGroupId)}})</option>
            </select>
          </div>
          <div class="form-group">
            <label>Solicitante</label>
            <input v-model="ticket.identificacao" type="text" class="form-control">
          </div>
          <div class="form-group">
            <label>Contato</label>
            <input v-model="ticket.contato" type="text" class="form-control">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Cliente</label>
            <div class="input-group">
              <input type="text" disabled v-bind:value="textoClienteSelecionado" class="form-control" placeholder="Selecione o cliente" aria-describedby="button-addon2">
              <button v-if="ticket.cliente_id"
                  v-on:click="removeClienteSelecionado()"
                  class="btn btn-outline-danger"
                  type="button">
                <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
              </button>
              <button v-on:click="abreModalPesquisaClientes()" class="btn btn-outline-primary" type="button" id="button-addon2">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
              </button>
            </div>
          </div>
          <div class="form-group">
            <label>Título</label>
            <input v-model="ticket.descricao_curta" type="text" class="form-control">
          </div>
          <div class="row">
            <div class="col-12 col-md">
              <label>Descrição</label>
              <textarea v-model="ticket.descricao_longa" class="form-control" style="min-height: 300px;"></textarea>
            </div>
            <div class="col-12 col-md-auto" style="min-width: 200px;">
              <label>Anexos</label>
              <br>
              <div class="form-group d-grid gap-2 pe-1">
                <input type="file" @change="handleFileUpload($event)" id="novo-anexo-file" class="d-none"/>
                <button v-on:click="novoAnexoHandle()" class="btn btn-sm btn-block btn-outline-primary">
                  <font-awesome-icon :icon="['fas', 'upload']" fixed-width class="me-1"/>Novo anexo
                </button>
                <hr class="m-1">
                <div v-for="anexo in anexosAtividade(0)" :key="anexo.id" class="row g-1">
                  <div class="col d-grid gap-2">
                    <button v-on:click="baixaAnexo(anexo)" class="btn btn-sm btn-block btn-outline-secondary">
                      <font-awesome-icon :icon="['fas', 'download']" fixed-width class="me-1"/>{{apenasNomeArquivo(anexo.caminho)}}
                    </button>
                  </div>
                  <div class="col-auto">
                    <button v-on:click="removeAnexoHandle(anexo)" class="btn btn-sm btn-outline-danger">
                      <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import store from '@/store'
import pesquisaCliente from '@/modules/pesquisa-cliente'

export default {
  name: 'TicketEdit',
  data() {
    return {
      meuID: 0,
      meuGrupoID: 0,
      ticketID: undefined,
      ticket: undefined,
      clienteSelecionado: undefined,
      carregandoAtividades: true,
      listaGrupos: [],
      listaOperadores: [],
      atividades: [],
      formAnotacao: {
        visivel: false,
        id: undefined,
        mensagem: '',
        tipo: '', // atMensagemPrivada, atMensagemPublica
      }
    }
  },
  methods: {
    resetFormAnotacao() {
      this.formAnotacao.id = undefined;
      this.formAnotacao.mensagem = '';
      this.formAnotacao.tipo = 'atMensagemPrivada';
    },

    carregaMeuID() {
      const userData = store.getters['auth/getUserData'];

      this.meuID = userData.userId;
      this.meuGrupoID = userData.helpdeskGroupId;
    },

    carregaTicket() {
      const apiUrl = `/helpdesk/tickets/${this.ticketID}`;

      loading(true);
      this.clienteSelecionado = undefined;

      api.get(apiUrl).then(async res => {
        this.ticket = res.data;

        if (this.ticket.cliente_id) {
          await pesquisaCliente(this.ticket.cliente_id).then(res => {
            this.clienteSelecionado = clone(res);
          }).catch(error => {
            dialogo.erroHttp(error);
          });
        }

        loading(false);
      }).catch(error => {
        loading(false);

        dialogo.erroHttp(error).then(() => {
          this.$router.push({ path: `/helpdesk` });
        });
      });
    },

    removeClienteSelecionado() {
      this.clienteSelecionado = undefined;
      this.ticket.cliente_id = 0;
    },

    abreModalPesquisaClientes() {
      pesquisaCliente().then(res => {
        this.clienteSelecionado = clone(res);
        this.ticket.cliente_id = res.id;
      });
    },

    carregaGrupos() {
      api.get(`/helpdesk/grupos?paginate.cancel&orderBy[prioridade]=desc`).then(res => {
        this.listaGrupos = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaGrupos();
        }, 1000);
      });
    },

    nomeGrupo(id) {
      if (!id) {
        return 'Nenhum';
      }

      const grupo = this.listaGrupos.find(x => x.id === id);

      if (!grupo) {
        return 'Não encontrado';
      }

      return grupo.descricao;
    },

    handleFileUpload(event){
      if (event.target.files.length <= 0) {
        return;
      }

      let formData = new FormData();
      formData.append(`anexo`, event.target.files[0]);

      loading(true);
      api.post(`/helpdesk/tickets/${this.ticketID}/anexos`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
        this.ticket.anexos_ticket.push(res.data);
        loading(false);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    novoAnexoHandle() {
      document.getElementById("novo-anexo-file").click();
    },

    // TODO: REFATORAR
    baixaAnexo(anexo) {
      const arquivo = this.apenasNomeArquivo(anexo.caminho);

      api.get(`/helpdesk/tickets/${this.ticketID}/anexos/${anexo.id}/${arquivo}`, {responseType: 'arraybuffer'}).then(res => {
        loading(false);

        let blob = new Blob([res.data], { type: 'application/octet-stream' }); // octet-stream
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.apenasNomeArquivo(anexo.caminho);
        link.click();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    // TODO: REFATORAR
    removeAnexoHandle(anexo) {
      dialogo.confirmacao(`Confirma remover o anexo: "${this.apenasNomeArquivo(anexo.caminho)}"?`).then(() => {
        api.delete(`/helpdesk/tickets/${this.ticketID}/anexos/${anexo.id}`).then(() => {
          loading(false);
          const index = this.ticket.anexos_ticket.indexOf(anexo);
            if (index > -1) {
              this.ticket.anexos_ticket.splice(index, 1);
            }
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    // TODO: REFATORAR
    anexosAtividade(id) {
      if (!this.ticket || !this.ticket.anexos_ticket) {
        return [];
      }

      const anexos = [];

      for (let index = 0; index < this.ticket.anexos_ticket.length; index++) {
        const anexo = this.ticket.anexos_ticket[index];
        if (anexo.mensagem_id === id) {
          anexos.push(anexo);
        }
      }

      return anexos;
    },

    // TODO: REFATORAR
    apenasNomeArquivo(caminho) {
      if (!caminho) {
        return '';
      }
      const pedacos = caminho.split('/');
      return pedacos[pedacos.length-1];
    },

    carregaOperadores() {
      api.get(`/usuarios?paginate.cancel&orderBy[nome]=desc`).then(res => {
        // this.listaOperadores = res.data.items;
        this.listaOperadores = [];

        res.data.itens.forEach(x => {
          if (x.id != this.meuID) {
            this.listaOperadores.push(x);
          }
        });

      }).catch(() => {
        setTimeout(() => {
          this.carregaOperadores();
        }, 1000);
      });
    },

    async confirmaSalvarAlteracoes() {
      if (this.ticket.estagio === 'etCriacao') {
        await dialogo.confirmacao(`Este ticket está "em criação", deseja liberar para atendimento?`).then(() => {
          this.ticket.estagio = 'etAberto';
        });

        return this.salvaAlteracoes();
      } else {
        return this.salvaAlteracoes();
      }
    },

    salvaAlteracoes() {
      const sendData = clone(this.ticket);

      loading(true);

      return api.put(`/helpdesk/tickets/${sendData.id}`, sendData).then(() => {
        loading(false);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    salvarHandle() {
      this.confirmaSalvarAlteracoes();
    },

    salvarESairHandle() {
      this.confirmaSalvarAlteracoes().then(() => {
        this.$router.push({ path: `/helpdesk` });
      });
    },

    editarTicketHandle() {
        this.$router.push({ path: `/helpdesk/tickets/${this.ticketID}/editar` });
    },

    abreNovaAnotacao() {
      this.formAnotacao.visivel = true;
    },

    fechaNovaAnotacao() {
      this.formAnotacao.visivel = false;
    },

    adicionarAnotacaoHandle() {
      const sendData = {
        tipo: this.formAnotacao.tipo,
        descricao: this.formAnotacao.mensagem,
      };

      loading(true);

      return api.post(`/helpdesk/tickets/${this.ticketID}/atividades`, sendData).then(() => {
        loading(false);
        this.resetFormAnotacao();
        this.fechaNovaAnotacao();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    atividadeValida(atividade) {
      //const atividades = ['atMensagemPrivada', 'atMensagemPublica'];
      //return atividades.includes(atividade.tipo);

      const atividades = ['atCriacao', 'atSolicitante', 'atContato', 'atCliente', 'atTitulo', 'atDescricao', 'atGrupo', 'atOperador'];
      return atividades.includes(atividade.acao);
    },

    escreveAtividade(atividade) {
      switch (atividade.acao) {
        case 'atCriacao':
          return 'Chamado criado.';

        case 'atSolicitante':
          return `Alteração do solicitante.\n • Anterior: ${atividade.descricao}`;

        case 'atContato':
          return `Alteração do contato.\n • Anterior: ${atividade.descricao}`;

        case 'atCliente':
          return `Alteração do cliente.\n • Anterior: ${atividade.descricao}`;

        case 'atTitulo':
          return `Alteração da descrição curta.\n • Anterior: ${atividade.descricao}`;

        case 'atDescricao':
          return `Alteração da descrição longa.\n • Anterior: ${atividade.descricao}`;

        case 'atGrupo':
          return `Alteração do grupo.\n • Anterior: ${atividade.descricao}`;

        case 'atOperador':
          return `Alteração do operador.\n • Anterior: ${atividade.descricao}`;
      }

      return 'Atividade inválida';
    },
  },
  created() {
    const ticketID = +this.$route.params.ticketID;

    if (!ticketID) {
      this.$router.push({ path: `/helpdesk` });
      return;
    }

    this.ticketID = ticketID;

    this.resetFormAnotacao();
    this.carregaMeuID();
    this.carregaTicket();
    this.carregaGrupos();
    this.carregaOperadores();
  },
  computed: {
    textoClienteSelecionado() {
      if (!this.clienteSelecionado) {
        return '';
      }

      return this.clienteSelecionado.razao_social;
    }
  },
}
</script>
